<template>
  <div class="p-4 md:w-1/3 sm:w-1/3 w-full">
    <div class="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
      <div class="lg:h-48 bg-gray-400 h-36 w-full object-cover object-center"></div>
      <div class="p-6">
        <h1 class="w-1/2 mb-4 h-6 custom-animate-pulse bg-gray-500"></h1>
        <p class="leading-relaxed mb-3 w-full h-3 custom-animate-pulse bg-gray-400"></p>
        <p class="leading-relaxed mb-3 w-2/3 h-3 custom-animate-pulse bg-gray-400"></p>
        <p class="leading-relaxed mb-3 w-1/2 h-3 custom-animate-pulse bg-gray-400"></p>
        <div class="flex flex-col justify-center items-center">
          <div class="w-full flex flex-row justify-center items-center mb-3">
            <span class="bg-gray-400 w-16 mt-2 h-3 custom-animate-pulse mr-3 px-2 leading-none text-sm pr-5 py-1"></span>
          </div>
          <a class="bg-indigo-300 h-12 custom-animate-pulse mt-2 w-36 inline-flex items-center md:mb-2 lg:mb-0"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonCardComponent'
};
</script>

<style>
@keyframes custom-pulse {
  50% {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.custom-animate-pulse {
  animation: custom-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>
