import { createApp } from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import '@/assets/tailwind.css';
import development from '@/config/development.json';
import production from '@/config/production.json';

const app = createApp(App);
if (process.env.NODE_ENV === 'production') {
  app.provide('config', Object.freeze(production));
} else {
  app.provide('config', Object.freeze(development));
}
app.provide('$store', store);
app.provide('router', router);
app.use(store);
app.use(router);
app.mount('#app');
