<template>
  <div class="container mx-auto">
    <section class="text-gray-700">
      <div class="px-4 py-32 mx-auto max-w-screen-xl lg:h-screen lg:items-center lg:flex">
        <div class="max-w-xl mx-auto text-center">
          <h1 class="text-3xl font-extrabold sm:text-5xl">
            Whoops!
            <strong class="text-9xl font-extrabold text-red-700 sm:block">404</strong>
            Page Not Found
          </h1>

          <p class="mt-4 sm:leading-relaxed sm:text-xl">
            This page got lost. You can head over to the
            <a href="/" class="text-blue-700">homepage</a>
            .
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'NotFoundComponent'
};
</script>
