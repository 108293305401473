<template>
  <nav id="header" class="fixed w-full">
    <!--Nav-->
    <div class="w-full z-10 relative top-0 bg-white border-b border-grey-light">
      <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div class="pl-4 flex items-center">
          <router-link to="/">
            <div style="color: #6600cc" class="font-extrabold text-xl cursor-pointer flex flex-row justify-center items-center">
              <img src="@/assets/logo_small.png" width="40" class="mr-2" />
              Udepon
            </div>
          </router-link>
          <div v-if="enableSearch && !isSearchable" @click="onSearchButtonClick" id="search-toggle" class="search-icon cursor-pointer pl-6">
            <svg class="fill-current pointer-events-none text-grey-darkest w-4 h-4 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
            </svg>
          </div>
        </div>

        <div class="w-full flex-grow flex flex-1 content-center justify-end mt-0 z-20" id="nav-content">
          <ul class="list-reset lg:flex justify-end items-center">
            <li class="mr-3 py-2 lg:py-0">
              <router-link to="/about"><div class="inline-block py-2 px-4 text-black font-bold cursor-pointer">About</div></router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--Search-->
    <div v-if="enableSearch && isSearchable" class="relative w-full bg-white shadow-xl" id="search-content">
      <div class="container mx-auto py-2 text-black">
        <input
          ref="searchfield"
          @blur="isSearchable = false"
          v-model="debouncedSearch"
          v-on:input="setDebouncedSearch"
          id="searchfield"
          type="search"
          placeholder="Search..."
          autofocus="autofocus"
          autocomplete="off"
          class="w-full text-grey-800 transition focus:outline-none focus:border-transparent p-2 appearance-none leading-normal text-l lg:text-xl"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import { nextTick } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  name: 'NavBarComponent',
  inject: ['config'],
  props: {
    enableSearch: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isSearchable: false,
      debouncedSearch: ''
    };
  },
  computed: {
    ...mapGetters({ $currentSearch: 'currentSearch' })
  },
  methods: {
    ...mapActions(['getCourseListBySearch']),
    onSearchButtonClick() {
      this.isSearchable = true;
      if (this.isSearchable) {
        nextTick().then(() => {
          this.$refs.searchfield.focus();
          if (!this.debouncedSearch) {
            this.debouncedSearch = this.$currentSearch;
          }
        });
      }
    },
    setDebouncedSearch: _.debounce(async function (e) {
      this.debouncedSearch = e.target.value;
      this.$emit('searching');
      await this.getCourseListBySearch({ search: this.debouncedSearch, pageSize: this.config.defaultPageSize });
      this.$emit('searched');
    }, 800)
  }
};
</script>
