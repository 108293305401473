<template>
  <div class="p-4 flex flex-row justify-center items-center">
    <nav aria-label="Page navigation">
      <ul class="inline-flex">
        <li><button @click="changePage(1)" class="px-4 py-2 text-udemy-color transition-colors duration-150 bg-white border border-r-0 boder-udemy-color rounded-l-lg focus:shadow-outline hover:bg-gray-200">First</button></li>
        <!-- <li v-if="currentPageNumber - 2 >= 1">
          <button @click="currentPageNumber = currentPageNumber - 2" class="px-4 py-2 text-udemy-color transition-colors duration-150 bg-white border border-r-0 boder-udemy-color focus:shadow-outline hover:bg-gray-200">{{ currentPageNumber - 2 }}</button>
        </li> -->
        <li v-if="currentPageNumber - 1 >= 1">
          <button
            @click="changePage(currentPageNumber > 1 ? currentPageNumber - 1 : currentPageNumber)"
            class="px-4 py-2 text-udemy-color transition-colors duration-150 bg-white border border-r-0 boder-udemy-color focus:shadow-outline hover:bg-gray-200"
          >
            {{ currentPageNumber - 1 }}
          </button>
        </li>
        <li>
          <button class="px-4 py-2 text-white transition-colors duration-150 bg-udemy-color border border-r-0 boder-udemy-color focus:shadow-outline">{{ currentPageNumber }}</button>
        </li>
        <li v-if="currentPageNumber + 1 <= lastPageNumber">
          <button
            @click="changePage(currentPageNumber < lastPageNumber ? currentPageNumber + 1 : currentPageNumber)"
            class="px-4 py-2 text-udemy-color transition-colors duration-150 bg-white border border-r-0 boder-udemy-color focus:shadow-outline hover:bg-gray-200"
          >
            {{ currentPageNumber + 1 }}
          </button>
        </li>
        <!-- <li v-if="currentPageNumber + 2 <= lastPageNumber">
          <button @click="currentPageNumber = currentPageNumber + 2" class="px-4 py-2 text-udemy-color transition-colors duration-150 bg-white border border-r-0 boder-udemy-color focus:shadow-outline hover:bg-gray-200">{{ currentPageNumber + 2 }}</button>
        </li> -->
        <li><button @click="changePage(lastPageNumber)" class="px-4 py-2 text-udemy-color transition-colors duration-150 bg-white border boder-udemy-color rounded-r-lg focus:shadow-outline hover:bg-gray-200">Last</button></li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PaginationComponent',
  inject: ['config'],
  data() {
    return {
      currentPageNumber: this.config.startPageNumber
    };
  },
  computed: {
    ...mapGetters({ $count: 'count', $currentPageNumber: 'currentPageNumber' }),
    lastPageNumber() {
      return Math.ceil(this.$count / this.config.defaultPageSize);
    }
  },
  watch: {
    $currentPageNumber(newValue) {
      this.currentPageNumber = newValue;
    }
  },
  methods: {
    changePage(newPageNumber) {
      const previousPageNumber = this.currentPageNumber;
      this.currentPageNumber = newPageNumber;
      this.$emit('pageChange', { currentPageNumber: this.currentPageNumber, previousPageNumber });
    }
  }
};
</script>

<style>
.text-udemy-color {
  color: #6600cc;
}
.boder-udemy-color {
  border-color: #6600cc;
}
.bg-udemy-color {
  background-color: #6600cc;
}
</style>
