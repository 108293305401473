import axios from 'axios';
// import { cacheAdapterEnhancer } from 'axios-extensions';

const baseDomain = 'https://udepon-backend.onrender.com'; // 'https://udemy-coupon.fly.dev';
// const baseDomain = 'http://localhost:3000';
const baseURL = `${baseDomain}/api`;

export default axios.create({
  baseURL,
  headers: { 'Cache-Control': 'no-cache, no-store, must-revalidate' }
  /** cache will be enabled by default */
  // adapter: cacheAdapterEnhancer(axios.defaults.adapter)
});
