<template>
  <!-- Nav Bar -->
  <NavBar v-bind:enableSearch="false"></NavBar>
  <div class="container mx-auto pt-24">
    <section class="overflow-hidden sm:grid sm:grid-cols-2 sm:items-center">
      <div class="p-8 md:p-12 lg:px-16 lg:py-24">
        <div class="mx-auto max-w-xl text-center sm:text-left">
          <h2 class="text-2xl font-bold text-gray-900 md:text-3xl">A site with 100% discounted Udemy coupons</h2>
          <p class="hidden text-gray-500 md:mt-4 md:block">
            At Udepon, my goal is to provide you with coupons as quickly as possible to help you save money. As you can imagine, if you are someone who loves to learn, it is painful to pay full price for each course, so you start looking
            online for free offers and discount codes. To be honest, it takes too long to find the code, but we save some money, which is great. But I want something simpler. I want a source that always has working code. However, the codes
            will not work forever as they may expire.
          </p>
          <div class="flex items-center mb-6 mt-4 md:mt-8">
            <img src="https://avatars.githubusercontent.com/u/46720991" class="rounded-full mr-2 h-8" alt="" loading="lazy" />
            <div>
              <span>
                Published
                <u>31.01.2023</u>
                by
              </span>
              <a href="https://github.com/potaesm" class="font-medium">Suthinan Musitmani</a>
            </div>
          </div>
        </div>
      </div>
      <img alt="Online study" src="@/assets/laptop_compressed.jpg" class="h-full w-full object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-tl-[30px] md:h-[calc(100%_-_4rem)] md:rounded-tl-[60px]" />
    </section>
  </div>
  <!-- Footer -->
  <Footer></Footer>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'AboutComponent',
  components: {
    NavBar,
    Footer
  },
};
</script>
