<template>
  <footer aria-label="Site Footer" class="bg-white">
    <div class="mx-auto max-w-screen-xl px-4 pt-8 pb-8 sm:px-6 lg:px-8">
      <div class="border-t border-gray-100 pt-8">
        <p ref="footerParagraph" class="text-center text-xs leading-relaxed text-gray-500"></p>
      </div>
    </div>
  </footer>
</template>

<script>
import { nextTick } from 'vue';
export default {
  name: 'FooterComponent',
  created() {
    nextTick(() => {
      this.$refs.footerParagraph.innerHTML = `© Copyright ${new Date().getFullYear()}. All rights reserved. <br> Created with <a href="https://vuejs.org/" class="text-gray-700 underline transition hover:text-gray-700/75">Vue 3</a> .`;
    });
  }
};
</script>
