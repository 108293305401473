import Repository from '@/repositories/RepositoryFactory';
const CourseRepository = Repository.get('courses');

const state = {
  currentPageNumber: 1,
  count: 0,
  courseList: {},
  search: '',
  onlyEnglish: false
};

const getters = {
  courseList: (state) => state.courseList,
  count: (state) => state.count,
  currentPageNumber: (state) => state.currentPageNumber,
  currentSearch: (state) => state.search,
  currentOnlyEnglish: (state) => state.onlyEnglish
};

const actions = {
  async getCourseListByPage({ state, commit }, { previousPageNumber, currentPageNumber, pageSize }) {
    try {
      commit('setCurrentPageNumber', { currentPageNumber });
      const response = await CourseRepository.getCourseList(currentPageNumber, pageSize, state.search, state.onlyEnglish);
      commit('loadCourseListByPage', { currentPageNumber, response });
    } catch (error) {
      commit('setCurrentPageNumber', { currentPageNumber: previousPageNumber });
      console.error(error);
    }
  },
  async getCourseListBySearch({ state, commit }, { search, pageSize }) {
    try {
      commit('setSearch', { search });
      commit('emptyCourseList')
      commit('setCurrentPageNumber', { currentPageNumber: 1 });
      const response = await CourseRepository.getCourseList(1, pageSize, search, state.onlyEnglish);
      commit('loadCourseListByPage', { currentPageNumber: 1, response });
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  loadCourseListByPage(
    state,
    {
      currentPageNumber,
      response: {
        data: { count, results }
      }
    }
  ) {
    /** Data mapping */
    results = results
      .filter((course) => course.type !== 'Affiliate')
      .map((course) => {
        const { name, image, shoer_description, sale_start } = course;
        let url = course.url;
        url = url.substring(url.lastIndexOf('https://'));
        return { title: name, image, excerpt: shoer_description, date: sale_start, url };
      });
    state.count = count;
    state.courseList[currentPageNumber - 1] = results;
  },
  setCurrentPageNumber(state, { currentPageNumber }) {
    state.currentPageNumber = currentPageNumber;
  },
  setSearch(state, { search }) {
    state.search = search;
  },
  emptyCourseList(state) {
    state.courseList = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
