import { createRouter, createWebHistory } from 'vue-router';
import Content from '@/components/Content/Content.vue';
import About from '@/components/About.vue';
import NotFound from '@/components/NotFound.vue';

const routes = [
  { path: '/', component: Content },
  { path: '/about', component: About },
  { path: '/:pathMatch(.*)*', component: NotFound }
];

export default createRouter({
  history: createWebHistory(),
  routes
});
