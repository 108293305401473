<template>
  <!-- Nav Bar -->
  <NavBar v-on:searching="emptyCourseList" v-on:searched="reloadPage"></NavBar>
  <!-- Course List -->
  <div class="container mx-auto">
    <section class="text-gray-700">
      <div class="container px-5 pt-24 mx-auto">
        <div v-if="!courseList.length" class="flex flex-wrap -m-4">
          <SkeletonCard v-for="number in config.defaultPageSize" :key="number"></SkeletonCard>
        </div>
        <div v-if="!!courseList.length" class="flex flex-wrap -m-4">
          <Card v-for="course in courseList" :key="course.id" :course="course"></Card>
        </div>
      </div>
    </section>
  </div>
  <!-- Pagination -->
  <Pagination v-on:page-change="async ({ currentPageNumber, previousPageNumber }) => updatePage(currentPageNumber, previousPageNumber)"></Pagination>
  <!-- Footer -->
  <Footer></Footer>
</template>

<script>
import Card from '@/components/Content/Card.vue';
import SkeletonCard from '@/components/Content/SkeletonCard.vue';
import Pagination from '@/components/Content/Pagination.vue';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ContentComponent',
  inject: ['config'],
  components: {
    SkeletonCard,
    Card,
    Pagination,
    NavBar,
    Footer
  },
  computed: {
    ...mapGetters({ $courseList: 'courseList', $currentPageNumber: 'currentPageNumber' })
  },
  methods: {
    ...mapActions(['getCourseListByPage']),
    async updatePage(currentPageNumber, previousPageNumber) {
      const newPageIndex = currentPageNumber - 1;
      // const currentCourseList = [...JSON.parse(JSON.stringify(this.$courseList))];
      if (!!this.$courseList[newPageIndex] && this.$courseList[newPageIndex].length) {
        this.courseList = Object.freeze([...this.$courseList[newPageIndex]]);
      } else {
        this.emptyCourseList();
        await this.getCourseListByPage({ previousPageNumber, currentPageNumber, pageSize: this.config.defaultPageSize });
        this.courseList = Object.freeze([...this.$courseList[this.$currentPageNumber - 1]]); // Read-only to improve performance
      }
      /** This prevents the page from scrolling down to where it was previously */
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
      }
      /** This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support */
      window.scrollTo(0, 0);
    },
    emptyCourseList() {
      this.courseList = [];
    },
    reloadPage() {
      this.courseList = Object.freeze([...this.$courseList[this.$currentPageNumber - 1]]); // Read-only to improve performance
    }
  },
  data() {
    return {
      courseList: []
    };
  },
  async created() {
    this.updatePage(this.config.startPageNumber, this.config.startPageNumber);
  }
};
</script>
