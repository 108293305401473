<template>
  <div class="p-4 md:w-1/3">
    <div class="h-full border-2 border-gray-200 rounded-lg overflow-hidden flex flex-col justify-between items-center">
      <div>
        <img class="w-full lg:h-48 md:h-36 object-cover object-center" :src="course.image" :alt="course.title" />
        <div class="p-6">
          <h1 class="text-left text-lg font-medium text-gray-900 mb-3">{{ course.title }}</h1>
          <p class="text-left leading-relaxed">{{ course.excerpt }}</p>
        </div>
      </div>
      <div class="w-full flex flex-col justify-center items-center pb-6">
        <div class="w-full flex flex-row justify-center items-center mb-3">
          <span class="text-gray-600 leading-relaxed text-sm">{{ course.date }}</span>
        </div>
        <a :href="course.url" class="text-udemy-color flex items-center justify-center rounded border border-current px-8 py-3 text-sm font-bold transition hover:scale-110 hover:shadow-xl focus:outline-none active:text-udemy-color">
          GET COUPON
          <svg class="ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 12 24" aria-hidden="true">
            <path
              style="stroke: none; fill-rule: nonzero; fill: rgb(64.313725%, 20.784314%, 94.117647%); fill-opacity: 1"
              d="M 11.988281 6.84375 L 5.992188 3.421875 L 0 6.84375 L 0 3.421875 L 5.992188 0 L 11.988281 3.421875 Z M 11.988281 6.84375 "
            />
            <path
              style="stroke: none; fill-rule: evenodd; fill: rgb(0%, 0%, 0%); fill-opacity: 1"
              d="M 3.140625 9.71875 L 3.140625 17.261719 C 3.140625 19.210938 4.613281 20.164062 5.992188 20.164062 C 7.386719 20.164062 8.847656 19.1875 8.847656 17.238281 L 8.847656 9.71875 L 11.988281 9.71875 L 11.988281 17.441406 C 11.988281 19.238281 11.417969 20.625 10.277344 21.574219 C 9.132812 22.523438 7.707031 22.984375 5.96875 22.984375 C 4.230469 22.984375 2.800781 22.523438 1.6875 21.574219 C 0.570312 20.625 0 19.289062 0 17.519531 L 0 9.71875 Z M 3.140625 9.71875 "
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  props: {
    course: {
      image: String,
      title: String,
      excerpt: String,
      date: String,
      url: String
    }
  }
};
</script>

<style>
.text-udemy-color {
  color: #6600cc;
}
</style>
